import React from 'react';
import { Form, message, Modal, Select } from 'antd';
import { useAppContext } from '../../../context';
import Request from '../../../api/request';
import { keyboard } from '@testing-library/user-event/dist/keyboard';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const CreateUserModal = (props: Props) => {
  const { visible, onClose } = props;
  const { appState } = useAppContext();
  const { user } = appState;
  const { company } = user;
  const [form] = Form.useForm<{
    companyID: string;
    adminEmails?: string[];
    editorEmails?: string[];
    verifierEmails?: string[];
  }>();

  React.useEffect(() => {
    if (visible) {
      if (company) {
        form.setFieldsValue({
          companyID: company.id
        });
      }
    }
  }, [visible]);

  const timeoutSearchCompany = React.useRef();
  const currentSearchKey = React.useRef('');
  const [companyOptions, setCompanyOptions] = React.useState([]);

  const handleSearch = async (newValue: string) => {
    if (newValue) {
      if (timeoutSearchCompany.current) {
        clearTimeout(timeoutSearchCompany.current);
        timeoutSearchCompany.current = null;
      }
      currentSearchKey.current = newValue;
      try {
        const res = await Request.getAuth('/companies', { limit: 20, searchKeyword: newValue });
        const { companies = [] } = res.data.data;
        setCompanyOptions(companies.map((item) => ({ value: item.id, label: item.name })));
      } catch (error) {
        message.error('Fail to get data');
      }
    } else {
      setCompanyOptions([]);
    }
  };

  const handleSubmit = async () => {
    try {
      const { companyID, editorEmails, verifierEmails, adminEmails } = await form.validateFields();
      const res = await Request.postAuth('/users', {
        companyID: companyID,
        editorEmails: editorEmails || [],
        verifierEmails: verifierEmails || [],
        adminEmails: adminEmails || []
      });
      if (res.data) {
        message.success('Add User success');
        form.resetFields();
        onClose();
      }
    } catch (error) {
      message.error(error.message || 'Fail to add user');
    }
  };
  return (
    <Modal title={'Add User'} visible={visible} onCancel={onClose} onOk={handleSubmit}>
      <Form form={form} layout={'vertical'}>
        <Form.Item
          name={'companyID'}
          label={'Company'}
          rules={[{ required: true, message: 'This is required field' }]}>
          {company ? (
            <Select disabled options={[{ value: company.id, label: company.name }]} />
          ) : (
            <Select
              showSearch
              placeholder={'Search company'}
              filterOption={false}
              onSearch={handleSearch}
              notFoundContent={null}
              options={companyOptions}
            />
          )}
        </Form.Item>

        <Form.Item name={'adminEmails'} label={'Admins'}>
          <Select mode={'tags'} placeholder={'Input email'} />
        </Form.Item>
        <Form.Item name={'editorEmails'} label={'Editor'}>
          <Select mode={'tags'} placeholder={'Input email'} />
        </Form.Item>

        <Form.Item name={'verifierEmails'} label={'Verifier'}>
          <Select mode={'tags'} placeholder={'Input email'} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default CreateUserModal;
