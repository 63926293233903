export const BooleanOptions = [
  {
    value: true,
    label: 'True'
  },
  {
    value: false,
    label: 'False'
  }
];

export const DisplayModeText = [
  {
    value: 1,
    label: 'Display as Title'
  },
  {
    value: 2,
    label: 'Display as Sub Title'
  }
];
