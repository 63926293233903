import React, { useEffect, useRef } from 'react';
import { message } from 'antd';
import Request from '../../../api/request';
import debounce from 'lodash/debounce';
import MarkerInActive from '../../../assets/marker_in_active.png';
import MarkerActive from '../../../assets/marker_active.png';

interface ISelectItem {
  id: string;
  details: any;
  geometry: any;
  status: number;
}
interface Props {
  projectID: string;
  selectedItem: ISelectItem;
  onChangeSelected: (selectedItem) => void;
}

const ProjectItemOnMap = (props: Props) => {
  const { projectID, selectedItem, onChangeSelected } = props;
  const refMap = useRef<google.maps.Map>();
  const refMarker = useRef<{ [id: string]: google.maps.Marker }>({});

  const addEventForMarker = (id, marker: google.maps.Marker) => {
    google.maps.event.addListener(marker, 'click', async () => {
      const res = await Request.getAuth(`/work_items/${id}`);
      if (res.data) {
        onChangeSelected(res.data.data);
      }
    });
  };

  useEffect(() => {
    const mapElement = document.getElementById('map-items');
    if (mapElement && !refMap.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      refMap.current = new window.google.maps.Map(mapElement, {
        center: {
          lat: selectedItem.geometry?.coordinates?.[1],
          lng: selectedItem.geometry?.coordinates?.[0]
        },
        zoom: 15,
        minZoom: 13,
        maxZoom: 20
      });
      const position = {
        lat: selectedItem.geometry?.coordinates?.[1],
        lng: selectedItem.geometry?.coordinates?.[0]
      };
      const marker = new window.google.maps.Marker({
        position,
        map: refMap.current
        // icon: MarkerActive
      });
      refMarker.current[selectedItem.id] = marker;
      refMarker.current[selectedItem.id].setMap(refMap.current);
      addEventForMarker(selectedItem.id, refMarker.current[selectedItem.id]);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      refMap.current.setCenter(position);
    }
  });

  const loadData = async () => {
    try {
      if (refMap.current) {
        const bounds = refMap.current.getBounds();
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();

        const res = await Request.getAuth('/work_items/ids', {
          minLat: Math.min(northEast.lat(), southWest.lat()),
          minLong: Math.min(northEast.lng(), southWest.lng()),
          maxLat: Math.max(northEast.lat(), southWest.lat()),
          maxLong: Math.max(northEast.lng(), southWest.lng()),
          projectID: projectID
        });

        const { workItemIDs = [] } = res.data.data;
        workItemIDs.forEach((workItem) => {
          if (refMarker.current[workItem.id]) {
            refMarker.current[workItem.id].setPosition({
              lat: workItem.geometry.coordinates[1],
              lng: workItem.geometry.coordinates[0]
            });
            refMarker.current[workItem.id].setMap(refMap.current);
          } else {
            const position = {
              lat: workItem.geometry?.coordinates?.[1],
              lng: workItem.geometry?.coordinates?.[0]
            };
            const marker = new window.google.maps.Marker({
              position,
              map: refMap.current,
              icon: MarkerInActive
            });
            refMarker.current[workItem.id] = marker;
            refMarker.current[workItem.id].setMap(refMap.current);
            addEventForMarker(workItem.id, refMarker.current[workItem.id]);
          }
        });
      }
    } catch (error) {
      message.error('Fail to load data');
    }
  };

  React.useEffect(() => {
    if (refMap.current) {
      google.maps.event.addListener(
        refMap.current,
        'bounds_changed',
        debounce(() => loadData(), 500)
      );
    }
    return () => {
      // google.maps.event.clearListeners(refMap.current, 'bounds_changed');
    };
  }, [refMap.current]);

  React.useEffect(() => {
    Object.keys(refMarker.current).forEach((workItemId) => {
      if (workItemId === selectedItem.id) {
        refMarker.current[workItemId].setIcon();
      } else {
        refMarker.current[workItemId].setIcon(MarkerInActive);
      }
      refMarker.current[workItemId].setMap(refMap.current);
    });
  }, [selectedItem]);

  return <div style={{ width: '100%', height: 'calc(100% - 50px)' }} id={'map-items'} />;
};

export default ProjectItemOnMap;
