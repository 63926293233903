import React, { useEffect, useRef, useState } from 'react';
import { useAppContext } from '../../context';
import { useNavigate, useParams } from 'react-router-dom';
import useAsync from '../../utils/useAsync';
import { IComment, ICommentType, IProject } from '../Projects/interface';
import Request from '../../api/request';
import ImageGallery from 'react-image-gallery';

import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Dropdown,
  Form,
  Input,
  Layout,
  List,
  Menu,
  message,
  Modal,
  PageHeader,
  Row,
  Select,
  Skeleton,
  Spin,
  Switch,
  Tag,
  Typography,
  Upload
} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';

import 'react-image-gallery/styles/scss/image-gallery.scss';
import './style.scss';

import API_CONFIG from '../../api/api_config';
import moment from 'moment';
import {
  WorkItemChangeStatus,
  WorkItemsStatusText,
  WorkItemStatus,
  WorkItemStatusColor,
  WorkItemType
} from '../../constants';
import { ExportOutlined, ImportOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import ProjectItemOnMap from './components/ProjectItemOnMap';
import ProjectItemDetail from './components/ProjectItemDetail';
import { useProjectById } from './hooks';
import { RcFile } from 'antd/lib/upload';
import { EUserRole } from '../User/interface';

export const getBasicKeyOfProject = (project: IProject) => {
  if (!project)
    return {
      titleField: {},
      subtitleField: {},
      imageField: {}
    };
  const titleField = project.metadata.fields.find(
    (field) => field.metadata.type === 'text' && field.metadata.displayMode === 1
  );

  const subtitleField = project.metadata.fields.find(
    (field) => field.metadata.type === 'text' && field.metadata.displayMode === 2
  );

  const imageField = project.metadata.fields.find(
    (field) => field.metadata.type === 'image' && field.metadata.displayingOnListView
  );
  return {
    titleField,
    subtitleField,
    imageField
  };
};

enum EnumModeView {
  MAP = 1,
  LIST = 2
}

export const getUrlImage = (name) => {
  return `${API_CONFIG.ROOT_DOMAIN}/files/${name}`;
};
const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={WorkItemStatusColor[value]}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}>
      {label}
    </Tag>
  );
};

const ProjectDetail = () => {
  const { appState } = useAppContext();
  const { user } = appState;
  const { newWorkItemAfterChangeStatus } = appState;
  //if no login redirect to login
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!appState?.user) {
      navigate('/');
    }
  }, []);

  const [viewMode, setViewMode] = React.useState(EnumModeView.LIST);
  const { projectId } = useParams();
  const projectSelected = useProjectById(projectId);
  const basicFieldOfProject = getBasicKeyOfProject(projectSelected);
  const [selectedItem, setSelectedItem] = React.useState<{
    id: string;
    details: any;
    geometry: any;
    status: number;
  }>();

  // work items

  const [workItems, setWorkItems] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const loading = React.useRef(false);
  const cursorLoadMore = React.useRef({});
  const [statuses, setStatuses] = React.useState([]);

  const onLoadMore = async () => {
    if (loading.current) {
      return;
    }
    loading.current = true;
    const response = await Request.getAuth('/work_items', {
      limit: 20,
      projectID: projectId,
      statuses: statuses.length === 0 ? undefined : statuses,
      ...cursorLoadMore.current
    });
    const { workItems: newWorkItems, cursor } = response.data.data;
    cursorLoadMore.current = cursor;
    if (workItems.length === 0 && newWorkItems.length > 0) {
      setSelectedItem(newWorkItems[0]);
    }
    if (newWorkItems.length < 20) {
      setHasMore(false);
    }
    setWorkItems((prevState) => [...prevState, ...newWorkItems]);
    loading.current = false;
  };

  React.useEffect(() => {
    onLoadMore();
  }, []);

  React.useEffect(() => {
    setWorkItems([]);
    setHasMore(true);
    cursorLoadMore.current = {};
    setSelectedItem(undefined);
    onLoadMore();
  }, [statuses, projectId]);

  //

  const refMap = useRef();

  useEffect(() => {
    const mapElement = document.getElementById('map');
    if (mapElement && !refMap.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      refMap.current = new window.google.maps.Map(mapElement, {
        center: { lat: 59.955413, lng: 30.337844 },
        zoom: 22
      });
    }
  });

  useEffect(() => {
    if (refMap.current && selectedItem) {
      const position = {
        lat: selectedItem.geometry?.coordinates?.[1],
        lng: selectedItem.geometry?.coordinates?.[0]
      };
      const marker = new window.google.maps.Marker({
        position,
        map: refMap.current
      });
      marker.setMap(refMap.current);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      refMap.current.setCenter(position);
    }
  }, [selectedItem]);

  const [loadingExport, setLoadingExport] = React.useState(false);
  const handleExportData = async () => {
    try {
      setLoadingExport(true);
      const res = await Request.getAuth(`/projects/${projectId}/reports`);
      if (res.data) {
        const { reportFile } = res.data.data;
        const a = document.createElement('a');
        a.href = `${API_CONFIG.ROOT_DOMAIN}/files/${reportFile}`;
        a.download = reportFile;
        document.body.appendChild(a);
        a.click();
        // document.body.removeChild(a);
      } else {
        message.error('Fail to export data');
      }
    } catch (error) {
      message.error('Fail to export data');
    } finally {
      setLoadingExport(false);
    }
  };

  const onChangeStatus = (newWorkItem) => {
    setWorkItems((prevState) => {
      return prevState.map((workItem) => {
        if (workItem.id === newWorkItem.id) {
          return newWorkItem;
        }
        return workItem;
      });
    });
    setSelectedItem(newWorkItem);
  };

  const [loadingImport, setLoadingImport] = React.useState(false);

  const handleImport = async (file) => {
    try {
      setLoadingImport(true);
      const formData = new FormData();
      formData.append('data', file);
      const response = await Request.postAuth(`/projects/${projectId}/work_items`, formData, {
        'Content-Type': 'multipart/form-data'
      });
      if (response.data) {
        message.success('Import data successfully');
      }
    } catch (error) {
      message.error(error);
    } finally {
      setLoadingImport(false);
    }
  };
  const beforeUploadAddNewMakeupFile = React.useCallback((file: RcFile) => {
    const reader = new FileReader();
    reader.onload = () => {
      handleImport(file);
      return false;
    };
    reader.readAsText(file, 'utf-8');
    return false;
  }, []);

  React.useEffect(() => {
    if (newWorkItemAfterChangeStatus) {
      onChangeStatus(newWorkItemAfterChangeStatus);
    }
  }, [newWorkItemAfterChangeStatus]);

  if (!appState?.user) {
    return null;
  }

  return (
    <Layout className={'projectDetail'}>
      <PageHeader
        className={'header'}
        onBack={() => navigate('/projects')}
        title={`${projectSelected?.company.name} - ${projectSelected?.name}`}
        subTitle={projectSelected?.description}
      />
      <div className={'listItemContainer'}>
        <div id="scrollableDiv" className={'listItem'}>
          <div className={'filter'}>
            <Select
              mode="multiple"
              placeholder={'Search status...'}
              maxTagCount={3}
              showArrow
              tagRender={tagRender}
              value={statuses}
              onChange={(newStatuses) => setStatuses(newStatuses)}
              style={{ width: '100%' }}
              options={Object.values(WorkItemStatus).map((status) => ({
                value: status,
                label: WorkItemsStatusText[status]
              }))}
            />
            <Button
              type={'primary'}
              danger={viewMode === EnumModeView.LIST}
              onClick={() =>
                setViewMode((preMode) =>
                  preMode === EnumModeView.LIST ? EnumModeView.MAP : EnumModeView.LIST
                )
              }>
              {viewMode === EnumModeView.LIST ? 'Map' : 'List'}
            </Button>
            {user?.role === EUserRole.ADMIN ? (
              <>
                <Button
                  loading={loadingExport}
                  type={'primary'}
                  onClick={handleExportData}
                  icon={<ExportOutlined />}>
                  Export
                </Button>
                <Upload accept={'.csv'} fileList={[]} beforeUpload={beforeUploadAddNewMakeupFile}>
                  <Button icon={<ImportOutlined />} loading={loadingImport} type={'primary'}>
                    Import
                  </Button>
                </Upload>
              </>
            ) : null}
          </div>
          {viewMode === EnumModeView.LIST ? (
            <InfiniteScroll
              dataLength={workItems.length}
              next={onLoadMore}
              hasMore={hasMore}
              loader={<Skeleton paragraph={{ rows: 1 }} active />}
              scrollableTarget="scrollableDiv">
              <List
                bordered
                className="listItem"
                dataSource={workItems}
                renderItem={(item) => (
                  <List.Item
                    style={{
                      background: selectedItem && selectedItem.id === item.id ? '#fce4bd' : 'white'
                    }}
                    onClick={() => {
                      setSelectedItem(item);
                    }}>
                    <List.Item.Meta
                      avatar={
                        basicFieldOfProject.imageField ? (
                          <Avatar
                            key={item}
                            size={'large'}
                            shape={'square'}
                            src={getUrlImage(item.details[basicFieldOfProject.imageField.key]?.[0])}
                          />
                        ) : null
                      }
                      title={
                        basicFieldOfProject.titleField
                          ? item.details[basicFieldOfProject.titleField.key]
                          : 'Click to view detail'
                      }
                      description={
                        basicFieldOfProject.subtitleField
                          ? item.details[basicFieldOfProject.subtitleField.key]
                          : ''
                      }
                    />
                    <div>
                      <div>
                        <Tag style={{ fontSize: 14 }} color={WorkItemStatusColor[item.status]}>
                          {WorkItemsStatusText[item.status]}
                        </Tag>
                      </div>
                      <div style={{ fontSize: 12 }}>
                        {moment(item.updatedAt).format('MMM DD, YYYY hh:mm')}
                      </div>
                      {/*<div>{item.id}</div>*/}
                    </div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          ) : (
            <ProjectItemOnMap
              projectID={projectId}
              selectedItem={selectedItem}
              onChangeSelected={setSelectedItem}
            />
          )}
        </div>
        {selectedItem && projectSelected ? (
          <ProjectItemDetail
            project={projectSelected}
            workItem={selectedItem}
            onChangeStatusFinnishCallback={onChangeStatus}
          />
        ) : (
          <div className={'projectContent'} />
        )}
      </div>
    </Layout>
  );
};

export default ProjectDetail;
