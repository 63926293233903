import React from 'react';
import { useAppContext } from '../../context';
import { useNavigate } from 'react-router-dom';
import Request from '../../api/request';
import { Button, Card, Descriptions, Input, List, PageHeader, Skeleton, Typography } from 'antd';
import './style.scss';
import { IProject } from './interface';
import InfiniteScroll from 'react-infinite-scroll-component';
import GrantPermissionForUser from './components/GrantPermissionForUser';
import {
  CopyOutlined,
  EditOutlined,
  FolderAddOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons';
import CreateProjectModal, { EnumOperation } from './components/CreateProject';

const { Paragraph } = Typography;
const { Search } = Input;

const Projects = () => {
  const { appState } = useAppContext();

  // //if no login redirect to login
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!appState?.user) {
      navigate('/');
    }
  }, []);

  const [projects, setProjects] = React.useState<IProject[]>([]);
  const [hasMore, setHasMore] = React.useState(true);
  const [keyWork, setKeyWork] = React.useState('');

  const loadingProject = React.useRef(false);
  const cursorLoadMore = React.useRef({});

  const onLoadMoreProject = async () => {
    if (loadingProject.current) {
      return;
    }
    loadingProject.current = true;
    const response = await Request.getAuth('/projects', {
      limit: 20,
      searchKeyword: keyWork.trim().length === 0 ? undefined : keyWork.trim(),
      returnNumberOfProjects: true,
      ...cursorLoadMore.current
    });

    const { projects: newProjects, cursor } = response.data.data;
    cursorLoadMore.current = cursor;
    if (newProjects.length < 20) {
      setHasMore(false);
    }
    setProjects((prevState) => [...prevState, ...newProjects]);
    loadingProject.current = false;
  };

  React.useEffect(() => {
    onLoadMoreProject();
  }, []);

  const resetData = () => {
    setProjects([]);
    setHasMore(true);
    cursorLoadMore.current = {};
    onLoadMoreProject();
  };

  React.useEffect(() => {
    resetData();
  }, [keyWork]);

  // grant permission for user

  const [visibleGrantPermForUser, setVisibleGrantPermForUser] = React.useState(false);
  const [selectedProject, setSelectedProject] = React.useState('');

  const [visibleProjectModal, setVisibleProjectModal] = React.useState({
    visible: false,
    operation: undefined,
    projectDetail: undefined
  });

  return (
    <div className={'projects'}>
      <PageHeader
        className={'header'}
        title={'Projects'}
        subTitle={
          <Search
            style={{ flex: 1, maxWidth: 400 }}
            placeholder="Search key work..."
            allowClear
            onSearch={(searchKey) => {
              setKeyWork(searchKey);
            }}
          />
        }
        extra={[
          <Button
            type={'primary'}
            key={'create_project'}
            icon={<FolderAddOutlined />}
            onClick={() =>
              setVisibleProjectModal({
                visible: true,
                projectDetail: undefined,
                operation: EnumOperation.CREATE
              })
            }>
            Create Projects
          </Button>
        ]}
      />
      <div id="scrollableDiv" className={'projectGridView'}>
        <InfiniteScroll
          dataLength={projects.length}
          next={onLoadMoreProject}
          hasMore={hasMore}
          loader={
            <Card style={{ width: 300, marginTop: 16 }} loading={loadingProject.current}>
              <Skeleton loading={loadingProject.current} avatar active />
            </Card>
          }
          scrollableTarget="scrollableDiv">
          <List
            grid={{
              gutter: 8,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 2,
              xl: 3,
              xxl: 3
            }}
            loading={loadingProject.current}
            dataSource={projects}
            renderItem={(project) => (
              <List.Item key={project.id} className={'projectItem'}>
                <Card
                  hoverable
                  title={
                    <Paragraph
                      style={{ fontSize: 16, width: '90%' }}
                      ellipsis={{
                        rows: 1,
                        tooltip: `${project.company.name} - ${project.name}`
                      }}>
                      {`${project.company.name} - ${project.name}`}
                    </Paragraph>
                  }
                  actions={[
                    <Button
                      icon={<UsergroupAddOutlined />}
                      type={'text'}
                      key={'btn_grant_perm'}
                      onClick={(e) => {
                        e.stopPropagation();
                        setVisibleGrantPermForUser(true);
                        setSelectedProject(project.id);
                      }}>
                      Grant User
                    </Button>,
                    <Button
                      type={'text'}
                      icon={<CopyOutlined />}
                      key={'btn_clone'}
                      onClick={(e) => {
                        e.stopPropagation();
                        setVisibleProjectModal({
                          visible: true,
                          operation: EnumOperation.CLONE,
                          projectDetail: project
                        });
                      }}>
                      Clone
                    </Button>,

                    <Button
                      type={'text'}
                      key={'btn_edit'}
                      icon={<EditOutlined />}
                      onClick={(e) => {
                        e.stopPropagation();
                        setVisibleProjectModal({
                          visible: true,
                          operation: EnumOperation.UPDATE,
                          projectDetail: project
                        });
                      }}>
                      Edit
                    </Button>
                  ]}
                  onClick={() => {
                    navigate(`/projects/${project.id}`);
                  }}>
                  <Paragraph
                    style={{ fontSize: 12, maxWidth: '90%' }}
                    ellipsis={{
                      rows: 1,
                      tooltip: project.description
                    }}>
                    {project.description}
                  </Paragraph>
                  <div>
                    <Descriptions size={'small'} column={3}>
                      <Descriptions.Item label="OnGoing">
                        {project.progress.ongoing || 0}
                      </Descriptions.Item>
                      <Descriptions.Item label="Completed">
                        {project.progress.completed}
                      </Descriptions.Item>
                      <Descriptions.Item label="Editor">
                        {project.numberOfEditors}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                </Card>
              </List.Item>
            )}
          />
        </InfiniteScroll>
        <GrantPermissionForUser
          projectId={selectedProject}
          visible={visibleGrantPermForUser}
          onClose={() => setVisibleGrantPermForUser(false)}
        />
        <CreateProjectModal
          visible={visibleProjectModal.visible}
          projectDetail={visibleProjectModal.projectDetail}
          operation={visibleProjectModal.operation}
          onClose={() => {
            setVisibleProjectModal({
              visible: false,
              projectDetail: undefined,
              operation: undefined
            });
            resetData();
          }}
        />
      </div>
    </div>
  );
};

export default Projects;
