import React, { useState } from 'react';
import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Dropdown,
  Form,
  List,
  Menu,
  message,
  Modal,
  Row,
  Skeleton,
  Spin,
  Typography
} from 'antd';
import {
  WorkItemChangeStatus,
  WorkItemsStatusText,
  WorkItemStatusColor,
  WorkItemType
} from '../../../constants';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import CommentDescription from './CommentDescription';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import Request from '../../../api/request';
import { IComment, IProject } from '../../Projects/interface';
import { getBasicKeyOfProject, getUrlImage } from '../index';
import ImageGallery from 'react-image-gallery';
const { Text } = Typography;

interface IProps {
  project: IProject;
  workItem: any;
  onChangeStatusFinnishCallback?: (newWorkItem) => void;
}

const ProjectItemDetail = (props: IProps) => {
  const { project, workItem, onChangeStatusFinnishCallback } = props;
  const basicFieldOfProject = getBasicKeyOfProject(project);
  const commentRef = React.useRef<HTMLElement>();

  //process commnet

  const [comments, setComments] = React.useState<IComment[]>([]);
  const loadingComment = React.useRef(false);
  const [hasMoreComment, setHasMoreComment] = useState(true);
  const cursorLoadMoreComment = React.useRef({});

  const onLoadMoreComment = async () => {
    try {
      if (loadingComment.current) {
        return;
      }
      loadingComment.current = true;
      const response = await Request.getAuth(`/work_items/${workItem.id}/comments`, {
        limit: 20,
        workItemID: workItem.id,
        ...cursorLoadMoreComment.current
      });
      const { comments: newComments, cursor } = response.data.data;
      cursorLoadMoreComment.current = cursor;
      if (newComments.length < 20) {
        setHasMoreComment(false);
      }
      setComments((prevState) => [...prevState, ...newComments]);
    } catch (e) {
      message.error('Fail when load comment');
    } finally {
      loadingComment.current = false;
    }
  };

  const [commentItem, setCommentItem] = React.useState('');
  const [loadingAddComment, setLoadingAddComment] = React.useState(false);

  const resetComment = () => {
    setComments([]);
    setHasMoreComment(true);
    setCommentItem('');
  };

  const onSubmitComment = async () => {
    try {
      setLoadingAddComment(true);
      const result = await Request.postAuth(`/work_items/${workItem.id}/comments`, {
        comment: commentItem
      });
      if (result) {
        message.success('Successfully');
        resetComment();
        onLoadMoreComment();
      }
    } catch (err) {
      message.error('Fail to add comment');
    } finally {
      setLoadingAddComment(false);
    }
  };

  const onChangeStatus = (e) => {
    const toStatus = parseInt(e.key);

    Modal.confirm({
      title: `Are you sure change to ${WorkItemsStatusText[toStatus]}`,
      onOk: async () => {
        try {
          const response = await Request.putAuth(`/work_items/${workItem.id}/status`, {
            newStatus: toStatus
          });
          if (response.data) {
            message.success('Successfully');
            onChangeStatusFinnishCallback?.(response.data.data);
            resetComment();
          }
        } catch (e) {
          message.error('Failt to update status');
        }
      }
    });
  };

  React.useEffect(() => {
    if (workItem) {
      onLoadMoreComment();
    }
  }, [workItem]);
  return (
    <div className={'projectContent'}>
      <div className={'projectContentHeader'}>
        <div>{workItem?.details?.[basicFieldOfProject?.titleField?.key] || 'N/A'}</div>
        <div className={'projectAction'}>
          <div>
            <Button
              onClick={() => {
                setTimeout(() => {
                  if (commentRef.current) {
                    commentRef.current?.focus();
                  }
                }, 200);
              }}>
              Comments
            </Button>
          </div>
          <Dropdown.Button
            placement={'bottomLeft'}
            overlay={
              <Menu
                onClick={onChangeStatus}
                items={WorkItemChangeStatus[workItem.status]?.map((item) => ({
                  label: WorkItemsStatusText[item],
                  icon: (
                    <span
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        borderRadius: '50%',
                        background: WorkItemStatusColor[item]
                      }}
                    />
                  ),
                  key: item.toString()
                }))}
              />
            }>
            <span
              style={{
                width: 10,
                height: 10,
                marginRight: 5,
                borderRadius: '50%',
                background: WorkItemStatusColor[workItem.status]
              }}
            />
            {WorkItemsStatusText[workItem.status]}
          </Dropdown.Button>
        </div>
      </div>
      <Form className="projectContentForm" layout={'vertical'}>
        {project.metadata.fields.map((item) => {
          if (
            item.metadata.type === WorkItemType.INTEGER ||
            item.metadata.type === WorkItemType.TEXT ||
            item.metadata.type === WorkItemType.FLOAT
          ) {
            return (
              <Form.Item key={item.key} label={item.title}>
                <Text>{workItem.details?.[item.key]}</Text>
              </Form.Item>
            );
          }
          if (item.metadata.type === WorkItemType.SELECTION) {
            return (
              <Form.Item key={item.key} label={item.title}>
                {item.metadata.items.length < 5 ? (
                  <Checkbox.Group value={workItem.details?.[item.key]}>
                    <Row>
                      {item.metadata.items.map((itemSelect) => (
                        <Col key={itemSelect.value} span={24}>
                          <Checkbox value={itemSelect.value} style={{ lineHeight: '32px' }}>
                            {itemSelect.name}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                ) : (
                  <Text>{workItem.details?.[item.key].toString()}</Text>
                )}
              </Form.Item>
            );
          }

          if (item.metadata.type === WorkItemType.IMAGE) {
            return (
              <Form.Item key={item.key} label={item.title}>
                <ImageGallery
                  showFullscreenButton={false}
                  showPlayButton={false}
                  slideDuration={200}
                  renderLeftNav={(onClick, disabled) => (
                    <button
                      style={{ fontSize: 24 }}
                      className="image-gallery-icon image-gallery-left-nav"
                      onClick={onClick}
                      disabled={disabled}
                      aria-label="Previous Slide">
                      <LeftOutlined />
                    </button>
                  )}
                  renderRightNav={(onClick, disabled) => (
                    <button
                      style={{ fontSize: 24 }}
                      className="image-gallery-icon image-gallery-right-nav"
                      onClick={onClick}
                      disabled={disabled}
                      aria-label="Next Slide">
                      <RightOutlined />
                    </button>
                  )}
                  items={
                    workItem.details?.[item.key]?.map((imageName) => ({
                      originalHeight: 240,
                      thumbnailHeight: 80,
                      original: getUrlImage(imageName),
                      thumbnail: getUrlImage(imageName)
                    })) || []
                  }
                />
              </Form.Item>
            );
          }

          if (item.metadata.type === WorkItemType.BOOLEAN) {
            return (
              <Form.Item key={item.key} label={item.title}>
                <Checkbox.Group value={workItem.details?.[item.key].toString()}>
                  <Checkbox value={'true'}>True</Checkbox>
                  <Checkbox value={'false'}>False</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            );
          }
          if (item.metadata.type === WorkItemType.LOCATION) {
            return (
              <Form.Item
                key={item.key}
                label={`${item.title} (${workItem.details?.[item.key]?.coordinates?.[0]},${
                  workItem.details?.[item.key]?.coordinates?.[1]
                })`}>
                <div style={{ height: '300px', width: '100%' }} id="map" />
              </Form.Item>
            );
          }
        })}
        <Form.Item key={'comment'} label="Comment">
          <Spin spinning={loadingAddComment}>
            <div id="scrollableCommentDiv">
              <InfiniteScroll
                dataLength={comments.length}
                next={onLoadMoreComment}
                hasMore={hasMoreComment}
                loader={<Skeleton paragraph={{ rows: 1 }} avatar active />}
                scrollableTarget="scrollableCommentDiv">
                <List
                  dataSource={comments}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar size={'large'} src={item.user.avatar} />}
                        title={item.user.email}
                        description={<CommentDescription data={item} />}
                      />
                      <div>{moment(item.createdAt).format('MMM DD, YYYY HH:mm')}</div>
                    </List.Item>
                  )}
                />
              </InfiniteScroll>
              <div className={'commentAction'}>
                <TextArea
                  ref={commentRef}
                  value={commentItem}
                  onChange={(e) => setCommentItem(e.target.value)}
                  rows={3}
                  placeholder={'Add Comment...'}
                />
                <Button className={'btn'} type={'primary'} onClick={() => onSubmitComment()}>
                  Comment
                </Button>
              </div>
            </div>
          </Spin>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ProjectItemDetail;
