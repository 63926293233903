import React from 'react';
import { Form, Input, message, Modal } from 'antd';
import Request from '../../../api/request';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const RegisterByEmail = (props: Props) => {
  const { visible, onClose } = props;
  const [form] = Form.useForm<{ name: string; email: string }>();

  const handleSubmit = async () => {
    try {
      const { name, email } = await form.validateFields();
      const res = await Request.post('/companies/register', {
        name,
        email
      });
      if (res.data) {
        message.success('Register successfully');
        onClose();
      }
    } catch (error) {
      message.error('Fail to register by email');
    }
  };

  return (
    <Modal
      title={'Register By Email'}
      visible={visible}
      onCancel={onClose}
      onOk={handleSubmit}
      okText={'Register'}>
      <Form form={form} layout={'vertical'}>
        <Form.Item
          name={'name'}
          label={'Company Name'}
          rules={[{ required: true, message: 'This is required field!' }]}>
          <Input placeholder={'Input company name'} />
        </Form.Item>
        <Form.Item
          name={'email'}
          label={'Admin Email'}
          rules={[{ required: true, message: 'This is required field!' }]}>
          <Input placeholder={'Input admin email'} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RegisterByEmail;
