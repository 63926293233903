import axios from 'axios';
import API_CONFIG from './api_config';

class Request {
  tokenId: string;
  getToken = () => {
    return this.tokenId;
  };
  callbackLogout: () => void;

  setToken = (token: string, callBackLogout) => {
    this.tokenId = token;
    this.callbackLogout = callBackLogout;
    this.instance.interceptors.response.use((res) => {
      if (res.status === 401) {
        callBackLogout?.();
      }
      return res;
    });
  };
  instance = axios.create({
    baseURL: API_CONFIG.ROOT_DOMAIN,
    withCredentials: true
  });

  post = (endPoint: string, data = {}) => {
    return this.instance
      .post(endPoint, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  };

  postAuth = (endPoint: string, data = {}, customHeader = {}) => {
    return this.instance
      .post(endPoint, data, {
        headers: {
          Authorization: `Bearer ${this.tokenId}`,
          ...customHeader
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  putAuth = (endPoint: string, data = {}) => {
    return this.instance
      .put(endPoint, data, {
        headers: {
          Authorization: `Bearer ${this.tokenId}`
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  getAuth = (endpoint, params = {}, delay = 0) => {
    return this.instance
      .get(endpoint, {
        params,
        headers: {
          Authorization: `Bearer ${this.tokenId}`
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}

export default new Request();
