import React from 'react';
import { useProjectById } from '../../../pages/ProjectDetail/hooks';
import { Button, Descriptions, Drawer, Typography } from 'antd';
import '../../../pages/ProjectDetail/style.scss';
import { SendOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { NotificationType } from '../../../pages/Projects/interface';

interface IProps {
  visible: boolean;
  type: NotificationType;
  title: string;
  onClose: () => void;
  projectID: string;
}
const ProjectDetailWrapper = (props: IProps) => {
  const navigate = useNavigate();
  const { visible, type, title, onClose, projectID } = props;

  const project = useProjectById(projectID);
  return (
    <Drawer
      visible={visible}
      title={
        <Typography.Paragraph
          style={{ width: 480, padding: 0, margin: 0 }}
          ellipsis={{
            rows: 1,
            tooltip: project?.name
          }}>
          {title}
        </Typography.Paragraph>
      }
      onClose={onClose}
      placement={'left'}
      width={600}>
      {project && (
        <div>
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Project ID">{project.id}</Descriptions.Item>
            <Descriptions.Item label="Project Name">{project?.name}</Descriptions.Item>
            <Descriptions.Item label="OnGoing">{project.progress.ongoing || 0}</Descriptions.Item>
            <Descriptions.Item label="Completed">{project.progress.completed}</Descriptions.Item>
            <Descriptions.Item label="Editor">{project.numberOfEditors}</Descriptions.Item>
          </Descriptions>
          {type === NotificationType.projectPermissionGranted && (
            <div
              style={{ display: 'flex', width: '100&', marginTop: 8, justifyContent: 'flex-end' }}>
              <Button
                type={'primary'}
                icon={<SendOutlined />}
                onClick={() => {
                  navigate(`/projects/${project.id}`);
                  onClose();
                }}>
                Go to Project
              </Button>
            </div>
          )}
        </div>
      )}
    </Drawer>
  );
};

export default ProjectDetailWrapper;
