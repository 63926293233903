import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBAjDYxDxJQANepD4hM_irjuq9dTXeRxHY',
  authDomain: 'gdts-data-collector.firebaseapp.com',
  projectId: 'gdts-data-collector',
  storageBucket: 'gdts-data-collector.appspot.com',
  messagingSenderId: '795673943674',
  appId: '1:795673943674:web:1d2e2889d9a4941a6865c3',
  measurementId: 'G-6YHXM0YVEW'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
