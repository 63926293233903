// This snippet file was generated by processing the source file:
// ./auth-next/google-signin.js
//
// To update the snippets in this file, edit the source and then run
// 'npm run snippets'.

// [START auth_google_provider_create_modular]
import { GoogleAuthProvider } from 'firebase/auth';

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });
export default provider;
// [END auth_google_provider_create_modular]
