import { EUserRole } from './interface';

export const UserRoleColor = {
  [EUserRole.ADMIN]: 'cyan',
  [EUserRole.VERIFY]: '#108ee9',
  [EUserRole.EDITOR]: '#87d068'
};

export const UserRoleText = {
  [EUserRole.ADMIN]: 'Admin',
  [EUserRole.VERIFY]: 'Verify',
  [EUserRole.EDITOR]: 'Editor'
};
