import React from 'react';
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  List,
  message,
  Modal,
  Select,
  Skeleton,
  Tag
} from 'antd';
import Request from '../../../api/request';
import Search from 'antd/lib/input/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IUser } from '../../User/interface';
import '../style.scss';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface Props {
  projectId?: string;
  visible: boolean;
  onClose: () => void;
}

const GrantPermissionForUser = (props: Props) => {
  const { projectId, visible, onClose } = props;
  const loadingUser = React.useRef(false);
  const [keyWork, setKeyWork] = React.useState('');
  const [users, setUsers] = React.useState<IUser[]>([]);
  const [listUserHasPermission, setListUserHasPermission] = React.useState<string[]>([]);
  const [hasMore, setHasMore] = React.useState(true);
  const cursorLoadMore = React.useRef({});
  const onLoadUser = async () => {
    try {
      if (loadingUser.current) {
        return;
      }
      loadingUser.current = true;
      const response = await Request.getAuth('/users', {
        limit: 20,
        roles: '2,3',
        searchKeyword: keyWork,
        ...cursorLoadMore.current
      });
      const { users: newUser, cursor } = response.data.data;
      cursorLoadMore.current = cursor;

      if (newUser.length > 0) {
        const resRoleOfUser = await Request.getAuth(`/projects/${projectId}/permissions`, {
          userIDs: newUser.map((item) => item.id).join(',')
        });
        setListUserHasPermission((prevState) => [...prevState, ...resRoleOfUser.data.data.userIDs]);
      }
      if (newUser.length < 20) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setUsers((prevState) => [...prevState, ...newUser]);
    } catch (error) {
      message.error('Fail to get user');
    } finally {
      loadingUser.current = false;
    }
  };

  const reloadData = () => {
    setUsers([]);
    setListUserHasPermission([]);
    setHasMore(true);
    cursorLoadMore.current = {};
    onLoadUser();
  };
  React.useEffect(() => {
    if (visible) {
      reloadData();
    }
  }, [keyWork, visible]);

  const handleChangePermission = (e: CheckboxChangeEvent) => async (userId?: string) => {
    const { checked } = e.target;
    try {
      const res = await Request.postAuth(`/projects/${projectId}/permissions`, {
        add: checked ? [userId] : [],
        remove: !checked ? [userId] : []
      });
      if (res.data) {
        message.destroy();
        message.success('Success');
        if (checked) {
          setListUserHasPermission((prevState) => [...prevState, userId]);
        } else {
          setListUserHasPermission((prevState) => prevState.filter((item) => item !== userId));
        }
      }
    } catch (error) {
      message.error('Fail to change permission');
    }
  };

  const handleClose = () => {
    setUsers([]);
    setListUserHasPermission([]);
    setKeyWork('');
    setHasMore(true);
    cursorLoadMore.current = {};
    onClose();
  };

  return (
    <Modal
      destroyOnClose
      className={'grantModal'}
      title={'Register By Email'}
      visible={visible}
      footer={<Button onClick={handleClose}>Close </Button>}>
      <Search
        placeholder="Search key work..."
        style={{ marginBottom: 12 }}
        allowClear
        onSearch={(searchKey) => {
          setKeyWork(searchKey);
        }}
      />
      <div id="scrollableDiv" className={'userGridView'}>
        <InfiniteScroll
          dataLength={users.length}
          next={onLoadUser}
          hasMore={hasMore}
          loader={
            <Card style={{ width: 300, marginTop: 16 }} loading={loadingUser.current}>
              <Skeleton loading={loadingUser.current} active />
            </Card>
          }
          scrollableTarget="scrollableDiv">
          <List
            style={{ maxHeight: '50vh', overflow: 'auto', paddingTop: 12, paddingBottom: 12 }}
            grid={{
              gutter: 8,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 1,
              xl: 1,
              xxl: 1
            }}
            bordered
            loading={loadingUser.current}
            dataSource={users}
            renderItem={(user) => (
              <List.Item
                key={user?.id}
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <List.Item.Meta
                  avatar={<Avatar src={user?.avatar} size={'large'} />}
                  title={<span>{user?.name || 'N/A'}</span>}
                  description={user?.email || 'N/A'}
                />
                <Checkbox
                  key={user?.id}
                  checked={listUserHasPermission.includes(user?.id)}
                  onChange={(e) => handleChangePermission(e)(user?.id)}
                />
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>
    </Modal>
  );
};

export default GrantPermissionForUser;
