import { auth } from './firebase_config';
import { signInWithPopup, signOut, GoogleAuthProvider } from 'firebase/auth';
import provider from './auth_google_provider_create';

export const signInWithGoogle = () => {
  return signInWithPopup(auth, provider)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const user = result.user;
      return {
        ...user,
        tokenId: credential?.idToken
      };
    })
    .catch((error) => {
      console.log(`=================== ${JSON.stringify(error)}`);
      throw error.errorMessage;
    });
};

export const logout = () => {
  return signOut(auth)
    .then(() => {
      return true;
    })
    .catch((error) => {
      return false;
    });
};
