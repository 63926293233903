export interface IProject {
  company: {
    id: string;
    name: string;
  };
  description: string;
  id: string;
  metadata: { version: number; fields: any };
  name: string;
  numberOfEditors: number;
  progress: { ongoing: number; completed: number };
}

export interface IComment {
  id: string;
  type: ICommentType;
  content: any;
  workItemID: string;
  projectID: string;
  user: {
    email: string;
    avatar: string;
  };
  createdAt: number;
}

export enum ICommentType {
  TEXT = 1,
  CREATE_WORK_ITEM = 2,
  UPDATE_WORK_ITEM_STATUS = 3,
  UPDATE_WORK_ITEM_DATA = 4
}

export enum NotificationType {
  comment = 1,
  projectPermissionGranted = 2,
  projectPermissionRevoked = 3
}
export interface INotification {
  id: string;
  type: NotificationType;
  seen: boolean;
  receiverID: string;
  sender: { email: string; avatar: string };
  comment?: IComment;
  project?: { projectID: string; name: string };
  message?: string;
  createdAt: number;
  workItemID?: string;
}
