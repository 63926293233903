export const WorkItemStatus = {
  DOING: 1,
  REVIEWING: 2,
  REJECTED: 3,
  COMPLETED: 4,
  ARCHIVED: 5
};

export const WorkItemStatusColor = {
  [WorkItemStatus.DOING]: '#2ecc71',
  [WorkItemStatus.REVIEWING]: '#f1c40f',
  [WorkItemStatus.REJECTED]: '#e74c3c',
  [WorkItemStatus.COMPLETED]: '#1abc9c',
  [WorkItemStatus.ARCHIVED]: '#34495e'
};

export const WorkItemsStatusText = {
  [WorkItemStatus.DOING]: 'Doing',
  [WorkItemStatus.REVIEWING]: 'Reviewing',
  [WorkItemStatus.REJECTED]: 'Rejected',
  [WorkItemStatus.COMPLETED]: 'Completed',
  [WorkItemStatus.ARCHIVED]: 'Archived'
};

export const WorkItemType = {
  INTEGER: 'integer',
  FLOAT: 'float',
  BOOLEAN: 'boolean',
  TEXT: 'text',
  IMAGE: 'image',
  LOCATION: 'location',
  SELECTION: 'selection'
};

export const WorkItemChangeStatus = {
  [WorkItemStatus.REVIEWING]: [WorkItemStatus.COMPLETED, WorkItemStatus.REJECTED],
  [WorkItemStatus.DOING]: [
    WorkItemStatus.REVIEWING,
    WorkItemStatus.ARCHIVED,
    WorkItemStatus.COMPLETED,
    WorkItemStatus.REJECTED
  ],
  [WorkItemStatus.ARCHIVED]: [
    WorkItemStatus.DOING,
    WorkItemStatus.REVIEWING,
    WorkItemStatus.COMPLETED,
    WorkItemStatus.REJECTED
  ],
  [WorkItemStatus.COMPLETED]: [
    WorkItemStatus.DOING,
    WorkItemStatus.REVIEWING,
    WorkItemStatus.ARCHIVED,
    WorkItemStatus.REJECTED
  ],
  [WorkItemStatus.REJECTED]: [
    WorkItemStatus.DOING,
    WorkItemStatus.REVIEWING,
    WorkItemStatus.ARCHIVED,
    WorkItemStatus.COMPLETED
  ]
};

export const RequireOption = {
  REQUIRED: {
    value: true,
    label: 'Required'
  },
  OPTIONAL: {
    value: false,
    label: 'Optional'
  }
};
