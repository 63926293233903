import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Login from './pages/Login';
import './App.css';
import Projects from './pages/Projects';
import { AppContext, IAppState } from './context';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Layout } from 'antd';
import AppHeader from './layout/Header';
import AppSideBar from './layout/SideBar';
import ProjectDetail from './pages/ProjectDetail';
import User from './pages/User';
import Company from './pages/Company';
import Request from './api/request';
import { logout } from './auth/auth_google_singin_popup';
import { EUserRole } from './pages/User/interface';

function App() {
  const local = localStorage.getItem('user');
  const navigate = useNavigate();
  const [appState = {}, setAppState] = React.useState<IAppState>(() => {
    if (local) {
      try {
        const user = JSON.parse(local);
        Request.setToken(user.token, () => {
          setAppState({});
          localStorage.removeItem('user');
          logout();
          navigate('/');
        });
        return user;
      } catch {
        return {};
      }
    }
    return {};
  });
  const { user = {} } = appState;
  const { company, role } = user;
  return (
    <GoogleOAuthProvider clientId="109618192843-quf4scomjago3u6ed1hhpfhlp415j8ce.apps.googleusercontent.com">
      <AppContext.Provider value={{ appState, setAppState }}>
        <Layout style={{ height: '100vh', overflow: 'hidden' }}>
          <AppHeader />
          <Layout>
            <AppSideBar />
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path={'/projects'} element={<Projects />} />
              <Route path={'/projects/:projectId'} element={<ProjectDetail />} />
              {role === EUserRole.ADMIN && <Route path={'/users'} element={<User />} />}
              {!company && <Route path={'/companies'} element={<Company />} />}
            </Routes>
          </Layout>
        </Layout>
      </AppContext.Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
