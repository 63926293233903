import React from 'react';
import { IProject } from '../pages/Projects/interface';

export interface IAppState {
  user?: any;
  collapsedSideBar?: boolean;
  projectSelected?: IProject;
  tokenId?: string;
  company?: { id: string; name: string };
  newWorkItemAfterChangeStatus?: any;
}

export interface IAppContext {
  appState?: IAppState;
  setAppState?: (newAppState: any) => void;
}

export const AppContext = React.createContext<IAppContext>({
  appState: undefined,
  setAppState: undefined
});

export const useAppContext = () => React.useContext(AppContext);
