import { LogoutOutlined, MenuFoldOutlined, NotificationOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Layout, Menu, Popover, Space } from 'antd';
import React from 'react';
import { useAppContext } from '../../context';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../auth/auth_google_singin_popup';
import Notification from './components/Notification';
import { EUserRole } from '../../pages/User/interface';

const { Header } = Layout;

const AppHeader = () => {
  const { appState, setAppState } = useAppContext();
  const navigate = useNavigate();
  const { user } = appState || {};
  const handleLogout = () => {
    setAppState({});
    localStorage.removeItem('user');
    logout();
    navigate('/');
  };
  const [open, setOpen] = React.useState(false);
  return (
    <>
      {user ? (
        <Header className={'headerPanel'}>
          <div className={'logoContainer'}>
            <span
              className={'collapse'}
              onClick={() =>
                setAppState((preState) => ({
                  ...preState,
                  collapsedSideBar: !preState.collapsedSideBar
                }))
              }>
              <MenuFoldOutlined />
            </span>
            <div className={'logo'}>GDTS Data Collector</div>
          </div>
          <div className={'rightContainer'}>
            <Space size="large">
              {user.role !== EUserRole.ADMIN && (
                <Popover
                  content={open ? <Notification /> : null}
                  visible={open}
                  placement={'bottomRight'}
                  trigger="click">
                  <Button
                    icon={<NotificationOutlined />}
                    onClick={() => setOpen((prevState) => !prevState)}
                    shape={'circle'}
                  />
                </Popover>
              )}
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item className={'userInfoWrapper'}>
                      <Avatar className={'avatar'} src={user.avatar}>
                        {user.avatar ? null : user.name}
                      </Avatar>
                      <div>
                        <div className={'username'}>{user.name}</div>
                        <div className={'email'}>{user.email}</div>
                      </div>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item>
                      <Button
                        onClick={() => handleLogout()}
                        className={'logout'}
                        type="text"
                        icon={<LogoutOutlined />}>
                        Logout
                      </Button>
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}>
                <Avatar src={user.avatar} className={'user'}>
                  {user.avatar ? null : user.name}
                </Avatar>
              </Dropdown>
            </Space>
          </div>
        </Header>
      ) : null}
    </>
  );
};

export default AppHeader;
