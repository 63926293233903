import React from 'react';
import { Form, Input, message, Modal } from 'antd';
import Request from '../../../api/request';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const CreateCompanyModal = (props: Props) => {
  const { visible, onClose } = props;
  const [form] = Form.useForm<{
    name: string;
  }>();
  const handleSubmit = async () => {
    try {
      const { name } = await form.validateFields();
      const res = await Request.postAuth('/companies', {
        name
      });
      if (res.data) {
        message.success('Add company successfully');
        form.resetFields();
        onClose();
      }
    } catch (error) {
      message.error(error.message || 'Fail to add company');
    }
  };
  return (
    <Modal title={'Add Company'} visible={visible} onCancel={onClose} onOk={handleSubmit}>
      <Form form={form} layout={'vertical'}>
        <Form.Item
          name={'name'}
          label={'Name'}
          rules={[{ required: true, message: 'This is required field!' }]}>
          <Input placeholder={'Input company name'} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default CreateCompanyModal;
