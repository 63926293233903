import React from 'react';
import { IProject } from '../Projects/interface';
import Request from '../../api/request';

export const useProjectById = (projectId) => {
  const [project, setProject] = React.useState<IProject>();

  const loadProject = async () => {
    const response = await Request.getAuth(`/projects/${projectId}`, { limit: 20 });

    const { project } = response.data.data;
    if (project) {
      setProject(project);
    } else {
      setProject(undefined);
    }
  };

  React.useEffect(() => {
    loadProject();
  }, [projectId]);
  return project;
};

export const useWorkItemById = (workItemId) => {
  const [workItem, setWorkItem] = React.useState();

  const loadWorkItem = async () => {
    const response = await Request.getAuth(`/work_items/${workItemId}`);

    if (response.data.data) {
      setWorkItem(response.data.data);
      return response.data.data;
    } else {
      setWorkItem(undefined);
      return undefined;
    }
  };

  React.useEffect(() => {
    loadWorkItem();
  }, [workItemId]);
  return {
    workItem,
    loadWorkItem
  };
};
