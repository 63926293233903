import React from 'react';
import { useProjectById, useWorkItemById } from '../../../pages/ProjectDetail/hooks';
import { Drawer } from 'antd';
import ProjectItemDetail from '../../../pages/ProjectDetail/components/ProjectItemDetail';
import '../../../pages/ProjectDetail/style.scss';
import { useAppContext } from '../../../context';

interface IProps {
  visible: boolean;
  onClose: () => void;
  projectID: string;
  workItemID: string;
}
const ProjectWorkItemCommentWrapper = (props: IProps) => {
  const { setAppState } = useAppContext();
  const { visible, onClose, projectID, workItemID } = props;

  const project = useProjectById(projectID);
  const { workItem, loadWorkItem } = useWorkItemById(workItemID);
  return (
    <Drawer
      visible={visible}
      title={`Work Item ID : ${workItemID}`}
      onClose={onClose}
      placement={'left'}
      width={800}>
      {project && workItem && (
        <ProjectItemDetail
          project={project}
          workItem={workItem}
          onChangeStatusFinnishCallback={async () => {
            const newWorkItem = await loadWorkItem();
            setAppState((preState) => ({
              ...preState,
              newWorkItemAfterChangeStatus: newWorkItem
            }));
          }}
        />
      )}
    </Drawer>
  );
};

export default ProjectWorkItemCommentWrapper;
