import React from 'react';
import { Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';

import { logout, signInWithGoogle } from '../../auth/auth_google_singin_popup';
import { useAppContext } from '../../context';
import Request from '../../api/request';
import { GoogleCircleFilled } from '@ant-design/icons';
import Logo from '../../assets/logo.jpg';
import RegisterByEmail from './components/RegisterByEmail';

const Login = () => {
  const navigate = useNavigate();
  const { appState, setAppState } = useAppContext();
  const { user } = appState || {};
  React.useEffect(() => {
    if (user) {
      navigate('/projects');
    }
  }, [user]);

  const signIn = React.useCallback(async () => {
    try {
      const user = await signInWithGoogle();
      if (user) {
        const authInfo = await Request.post('/auth/login', {
          googleIDToken: user.tokenId
        });
        Request.setToken(authInfo.data.token, () => {
          setAppState({});
          localStorage.removeItem('user');
          logout();
          navigate('/');
        });
        setAppState(authInfo.data);
        localStorage.setItem('user', JSON.stringify(authInfo.data));
        navigate('/projects');
      }
    } catch (error) {
      message.error('Fail to login');
      console.log(`=================== ${error} ${JSON.stringify(error)}`);
    }
  }, []);

  const [visibleRegisterByEmail, setVisibleRegisterByEmail] = React.useState(false);
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <div
        style={{
          width: 300,
          height: 250,
          background: '#ffffff',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 16
        }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'column'
          }}>
          <img style={{ width: 50, height: 50 }} src={Logo} />
          <div>GTDS</div>
          <div>Thu thập dữ liệu bản đồ</div>
        </div>
        <Button
          style={{ width: '70%' }}
          danger
          onClick={() => signIn()}
          icon={<GoogleCircleFilled />}>
          Login with Google
        </Button>
        <Button style={{ width: '70%' }} onClick={() => setVisibleRegisterByEmail(true)}>
          Register by email
        </Button>
        <RegisterByEmail
          visible={visibleRegisterByEmail}
          onClose={() => setVisibleRegisterByEmail(false)}
        />
      </div>
    </div>
  );
};

export default Login;
