import React from 'react';
import { IComment, ICommentType } from '../../Projects/interface';
import { WorkItemsStatusText, WorkItemStatusColor } from '../../../constants';

interface IProps {
  data: IComment;
}
const CommentDescription = ({ data }: IProps) => {
  return (
    <div>
      {data.type === ICommentType.TEXT && <span>{data.content?.text}</span>}
      {data.type === ICommentType.CREATE_WORK_ITEM && <span>Taọ dữ liệu</span>}
      {data.type === ICommentType.UPDATE_WORK_ITEM_STATUS && (
        <span>
          <span>Thay đổi trạng thái từ </span>
          <span
            style={{
              fontSize: 14,
              color: WorkItemStatusColor[data?.content?.fromStatus]
            }}>
            {WorkItemsStatusText[data?.content?.fromStatus]}
          </span>
          <span> sang </span>
          <span
            style={{
              fontSize: 14,
              color: WorkItemStatusColor[data?.content?.toStatus]
            }}>
            {WorkItemsStatusText[data?.content?.toStatus]}
          </span>
        </span>
      )}
      {data.type === ICommentType.UPDATE_WORK_ITEM_DATA && <span>Cập nhật dữ liệu dữ liệu</span>}
    </div>
  );
};

export default CommentDescription;
