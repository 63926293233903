import React from 'react';
import {
  Avatar,
  Badge,
  Button,
  Card,
  Descriptions,
  Input,
  List,
  message,
  PageHeader,
  Skeleton,
  Tag
} from 'antd';
import Request from '../../api/request';

import './style.scss';
import { IUser } from './interface';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAppContext } from '../../context';
import { useNavigate } from 'react-router-dom';
import CreateUserModal from './components/CreateUserModal';
import { UserRoleColor, UserRoleText } from './constant';
const { Search } = Input;

const User = () => {
  const { appState, setAppState } = useAppContext();

  // //if no login redirect to login
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!appState?.user) {
      navigate('/');
    }
  }, []);

  const loadingUser = React.useRef(false);
  const [keyWork, setKeyWork] = React.useState('');
  const [users, setUsers] = React.useState<IUser[]>([]);
  const [hasMore, setHasMore] = React.useState(true);
  const cursorLoadMore = React.useRef({});
  const onLoadUser = async () => {
    try {
      if (loadingUser.current) {
        return;
      }
      loadingUser.current = true;
      const response = await Request.getAuth('/users', {
        limit: 20,
        roles: '1,2,3',
        searchKeyword: keyWork,
        ...cursorLoadMore.current
      });

      const { users: newUser, cursor } = response.data.data;
      cursorLoadMore.current = cursor;
      if (newUser.length < 20) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setUsers((prevState) => [...prevState, ...newUser]);
    } catch (error) {
      message.error('Fail to get user');
    } finally {
      loadingUser.current = false;
    }
  };

  const reloadData = () => {
    setUsers([]);
    setHasMore(true);
    cursorLoadMore.current = {};
    onLoadUser();
  };
  React.useEffect(() => {
    reloadData();
  }, [keyWork]);

  // add user modal

  const [visibleModalAddUser, setVisibleModalAddUser] = React.useState(false);
  return (
    <div className={'users'}>
      <PageHeader
        className={'header'}
        title={'Users'}
        subTitle={
          <Search
            style={{ width: 500 }}
            placeholder="Search key work..."
            allowClear
            onSearch={(searchKey) => {
              setKeyWork(searchKey);
            }}
          />
        }
        extra={[
          <Button key="add_user_btn" type={'primary'} onClick={() => setVisibleModalAddUser(true)}>
            Add Users
          </Button>
        ]}
      />
      <div id="scrollableDiv" className={'userGridView'}>
        <InfiniteScroll
          dataLength={users.length}
          next={onLoadUser}
          hasMore={hasMore}
          loader={
            <Card style={{ width: 300, marginTop: 16 }} loading={loadingUser.current}>
              <Skeleton loading={loadingUser.current} active />
            </Card>
          }
          scrollableTarget="scrollableDiv">
          <List
            grid={{
              gutter: 8,
              xs: 1,
              sm: 1,
              md: 2,
              lg: 3,
              xl: 4,
              xxl: 4
            }}
            loading={loadingUser.current}
            dataSource={users}
            renderItem={(user) => (
              <Card hoverable style={{ margin: 8 }}>
                <List.Item
                  key={user?.id}
                  actions={[
                    <span key={'1'}>
                      <Tag color={UserRoleColor[user?.role]}>{UserRoleText[user?.role]}</Tag>
                    </span>
                  ]}>
                  <List.Item.Meta
                    avatar={<Avatar src={user?.avatar} size={'large'} />}
                    title={<span>{user?.name || 'N/A'}</span>}
                    description={user?.email || 'N/A'}
                  />
                </List.Item>
              </Card>
            )}
          />
        </InfiniteScroll>
      </div>
      <CreateUserModal
        visible={visibleModalAddUser}
        onClose={() => {
          setVisibleModalAddUser(false);
          reloadData();
        }}
      />
    </div>
  );
};

export default User;
