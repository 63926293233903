import React from 'react';
import {
  Button,
  Card,
  Checkbox,
  Collapse,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select
} from 'antd';
import { useAppContext } from '../../../context';
import Request from '../../../api/request';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { WorkItemType } from '../../../constants';
import '../style.scss';
import { BooleanOptions, DisplayModeText } from '../constant';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IProject } from '../interface';

export enum EnumOperation {
  CREATE = 1,
  UPDATE = 2,
  CLONE = 3
}
interface Props {
  visible: boolean;
  projectDetail?: IProject;
  operation: EnumOperation;
  onClose: () => void;
}
interface ProjectFormData {
  name: string;
  id?: string;
  description: string;
  company: string;
  metadata: {
    key: string;
    title: string;
    required: boolean;
    metadata: {
      type: string;
      min?: number;
      max?: number;
      default?: number | boolean;
      maxLength?: number;
      minLength?: number;
      displayMode?: number;
      displayingOnListView?: boolean;
      isUsedForDisplaying?: boolean;
    };
  }[];
}

const CreateProjectModal = (props: Props) => {
  const { visible, projectDetail, operation, onClose } = props;
  const { appState } = useAppContext();
  const { user } = appState;
  const { company } = user;
  const [form] = Form.useForm<ProjectFormData>();

  React.useEffect(() => {
    if (visible && company) {
      form.setFieldsValue({
        company: company.id
      });
    }
  }, [visible, company]);

  const canEdit = operation !== EnumOperation.UPDATE;
  const [loading, setLoading] = React.useState(false);
  const handleClose = () => {
    form.resetFields();
    form.setFieldsValue({
      name: undefined,
      metadata: undefined,
      company: undefined,
      description: undefined
    });
    onClose();
  };

  React.useEffect(() => {
    if (visible && projectDetail) {
      const { name, company, metadata, description } = projectDetail;
      setCompanyOptions([{ value: company.id, label: company.name }]);
      form.setFieldsValue({
        name,
        description,
        company: company.id,
        metadata: metadata.fields
      });
    }
  }, [visible, projectDetail]);

  const timeoutSearchCompany = React.useRef();
  const currentSearchKey = React.useRef('');
  const [companyOptions, setCompanyOptions] = React.useState([]);

  const handleSearch = async (newValue: string) => {
    if (newValue) {
      if (timeoutSearchCompany.current) {
        clearTimeout(timeoutSearchCompany.current);
        timeoutSearchCompany.current = null;
      }
      currentSearchKey.current = newValue;
      try {
        const res = await Request.getAuth('/companies', { limit: 20, searchKeyword: newValue });
        const { companies = [] } = res.data.data;
        setCompanyOptions(companies.map((item) => ({ value: item.id, label: item.name })));
      } catch (error) {
        message.error('Fail to get data');
      }
    } else {
      setCompanyOptions([]);
    }
  };

  const handleSubmit = async () => {
    try {
      form.validateFields();
      const { name, company, description, metadata } = form.getFieldsValue();
      setLoading(true);

      const data = {
        id: operation === EnumOperation.UPDATE ? projectDetail.id : undefined,
        name: name,
        description: description,
        company: company,
        metadata: {
          version: 0,
          fields: metadata
        }
      };
      const res =
        operation === EnumOperation.UPDATE
          ? await Request.putAuth(`/projects/${projectDetail.id}`, data)
          : await Request.postAuth('/projects', data);
      if (res?.data?.data) {
        message.success('Create success');
        handleClose();
      } else {
        message.error('Fail to create');
      }
    } catch (e) {
      message.error(e);
    } finally {
      setLoading(false);
    }
  };
  const itemStyle = (isDragging, draggableStyle) => ({
    padding: 8,
    background: isDragging ? '#535ADF' : 'none',
    ...draggableStyle
  });
  const listStyle = (isDraggingOver) => ({
    paddingBottom: 200,
    background: isDraggingOver ? '#E8E8E8' : 'none'
  });

  const onDragEnd = (result) => {
    const { source, destination } = result;
    const tempValue = form.getFieldValue(['metadata', source.index]);

    form.setFieldValue(
      ['metadata', source.index],
      form.getFieldValue(['metadata', destination.index])
    );
    form.setFieldValue(['metadata', destination.index], tempValue);
  };

  const validateMax = (minValue, value) => {
    if (value == undefined) {
      return Promise.resolve();
    }
    if (value < 0) {
      return Promise.reject(new Error('Must be than 0'));
    }
    if (minValue && minValue > value) {
      return Promise.reject(new Error('Must be than min'));
    }
  };

  const validateKey = (metadata, value) => {
    if (value == undefined) {
      return Promise.resolve();
    }
    const filterByValue = metadata?.filter((item) => item.key === value) || [];
    if (filterByValue.length > 1) {
      return Promise.reject(new Error('Key has existed'));
    }
  };

  const validateMin = (maxValue, value, mustGreaterThanZero = false) => {
    if (value == undefined) {
      return Promise.resolve();
    }
    if (mustGreaterThanZero && value < 0) {
      return Promise.reject(new Error('Must be than 0'));
    }
    if (maxValue && maxValue < value) {
      return Promise.reject(new Error('Must be less max'));
    }
  };
  const validateDefault = (minValue, maxValue, value) => {
    if (value == undefined) {
      return Promise.resolve();
    }
    if (value < 0) {
      return Promise.reject(new Error('Must be than 0'));
    }
    if (maxValue && maxValue < value) {
      return Promise.reject(new Error('Must be less max'));
    }
    if (minValue && minValue > value) {
      return Promise.reject(new Error('Must be than min'));
    }
  };

  const validateDisplayMode = (metaData, value) => {
    if (value == undefined) {
      return Promise.resolve();
    }
    const filterByValue =
      metaData?.map((item) => item.metadata)?.filter((item) => item.displayMode === value) || [];
    if (filterByValue.length > 1) {
      return Promise.reject(new Error('DisplayMode has existed'));
    }
  };

  const validateDisplayOnListView = (metaData, value) => {
    if (value == undefined || !value) {
      return Promise.resolve();
    }
    const filterByValue =
      metaData?.map((item) => item.metadata)?.filter((item) => item.displayingOnListView) || [];
    if (filterByValue.length > 1) {
      return Promise.reject(new Error('This config must only'));
    }
  };

  const validateDisplaying = (metaData, value) => {
    if (value == undefined || !value) {
      return Promise.resolve();
    }
    const filterByValue =
      metaData?.map((item) => item.metadata)?.filter((item) => item.isUsedForDisplaying) || [];
    if (filterByValue.length > 1) {
      return Promise.reject(new Error('This config must only'));
    }
  };

  const validateSelection = (listSelections, key, value) => {
    if (value == undefined || !value) {
      return Promise.resolve();
    }
    const filterByValue = listSelections?.filter((item) => item[key] === value) || [];
    if (filterByValue.length > 1) {
      return Promise.reject(new Error('Value has existed'));
    }
  };

  return (
    <Modal
      className={'createProgramModal'}
      width={1200}
      title={'Create Project'}
      destroyOnClose
      onCancel={handleClose}
      visible={visible}
      confirmLoading={loading}
      okText={
        operation === EnumOperation.CREATE
          ? 'Create'
          : operation === EnumOperation.UPDATE
          ? 'Update'
          : 'Clone'
      }
      onOk={handleSubmit}>
      <Form
        form={form}
        layout={'horizontal'}
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 22 }}
        onFinish={handleSubmit}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Form.Item
            name={'company'}
            label={'Company'}
            rules={[{ required: true, message: 'This is required field' }]}>
            {company ? (
              <Select disabled options={[{ value: company.id, label: company.name }]} />
            ) : (
              <Select
                showSearch
                allowClear
                placeholder={'Search company'}
                filterOption={false}
                onSearch={handleSearch}
                notFoundContent={null}
                options={companyOptions}
              />
            )}
          </Form.Item>
          <Form.Item
            label={'Name'}
            name={'name'}
            rules={[{ required: true, message: 'This is required field' }]}>
            <Input placeholder={'Input project name'} />
          </Form.Item>
          <Form.Item
            label={'Description'}
            name={'description'}
            rules={[{ required: true, message: 'This is required field' }]}>
            <Input.TextArea placeholder={'Input description'} />
          </Form.Item>
          <Form.Item label={'Metadata'} />
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={listStyle(snapshot.isDraggingOver)}>
                <Form.List name={'metadata'}>
                  {(fields, { add, remove, move }, { errors }) => (
                    <>
                      {fields.map((field, index) => {
                        return (
                          <Draggable
                            key={`item-${field.name}`}
                            draggableId={`item-${field.name}`}
                            index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={itemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}>
                                <Card key={field.key} className={'cardRow'}>
                                  <div className={'metadataRow'}>
                                    <Form.Item
                                      className={'rowItem'}
                                      labelAlign={'right'}
                                      labelCol={{ span: 4 }}
                                      label={'Type'}
                                      rules={[
                                        { required: true, message: 'This is required field' }
                                      ]}
                                      name={[field.name, 'metadata', 'type']}
                                      validateTrigger={['onChange', 'onBlur']}>
                                      <Select
                                        placeholder={'Select type'}
                                        disabled={!canEdit}
                                        onChange={(newType) => {
                                          form.resetFields([['metadata', field.name, 'metadata']]);
                                          form.setFieldValue(
                                            ['metadata', field.name, 'metadata', 'type'],
                                            newType
                                          );
                                        }}
                                        options={Object.values(WorkItemType).map((item) => ({
                                          value: item,
                                          label: item.toUpperCase()
                                        }))}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      className={'rowItem'}
                                      labelAlign={'right'}
                                      labelCol={{ span: 4 }}
                                      label={'Key'}
                                      rules={[
                                        { required: true, message: 'This is required field' },
                                        ({ getFieldValue }) => ({
                                          validator(_, value) {
                                            return validateKey(getFieldValue(['metadata']), value);
                                          }
                                        })
                                      ]}
                                      name={[field.name, 'key']}
                                      validateTrigger={['onChange', 'onBlur']}>
                                      <Input placeholder={'Input key'} disabled={!canEdit} />
                                    </Form.Item>
                                    <Form.Item
                                      className={'rowItem'}
                                      labelAlign={'right'}
                                      labelCol={{ span: 4 }}
                                      label={'Title'}
                                      rules={[
                                        { required: true, message: 'This is required field' }
                                      ]}
                                      name={[field.name, 'title']}
                                      validateTrigger={['onChange', 'onBlur']}>
                                      <Input placeholder={'Input title'} disabled={!canEdit} />
                                    </Form.Item>
                                    <Form.Item
                                      className={'rowItemFitContent'}
                                      initialValue={false}
                                      valuePropName={'checked'}
                                      name={[field.name, 'required']}
                                      validateTrigger={['onChange', 'onBlur']}>
                                      <Checkbox disabled={!canEdit}>Required</Checkbox>
                                    </Form.Item>
                                    <Button
                                      className={'btn'}
                                      danger
                                      icon={<DeleteOutlined />}
                                      onClick={() => remove(field.name)}
                                    />
                                  </div>
                                  <Form.Item
                                    noStyle
                                    dependencies={[
                                      ['metadata', field.name],
                                      ['metadata', field.name, 'metadata', 'type'],
                                      ['metadata', field.name, 'metadata', 'displayMode'],
                                      ['metadata', field.name, 'metadata', 'displayingOnListView'],
                                      ['metadata', field.name, 'metadata', 'isUsedForDisplaying']
                                    ]}>
                                    {() => {
                                      if (
                                        form.getFieldValue([
                                          'metadata',
                                          field.name,
                                          'metadata',
                                          'type'
                                        ]) === WorkItemType.INTEGER ||
                                        form.getFieldValue([
                                          'metadata',
                                          field.name,
                                          'metadata',
                                          'type'
                                        ]) === WorkItemType.FLOAT
                                      ) {
                                        return (
                                          <div className={'metadataRow'}>
                                            <Form.Item
                                              labelAlign={'right'}
                                              labelCol={{ span: 6 }}
                                              dependencies={[
                                                ['metadata', field.name, 'metadata', 'min'],
                                                ['metadata', field.name, 'metadata', 'max']
                                              ]}
                                              className={'rowItemExtraData'}
                                              label={'Min'}
                                              validateTrigger={['onChange', 'onBlur']}
                                              rules={[
                                                ({ getFieldValue }) => ({
                                                  validator(_, value) {
                                                    return validateMin(
                                                      getFieldValue([
                                                        'metadata',
                                                        field.name,
                                                        'metadata',
                                                        'max'
                                                      ]),
                                                      value,
                                                      false
                                                    );
                                                  }
                                                })
                                              ]}
                                              name={[field.name, 'metadata', 'min']}>
                                              <InputNumber
                                                disabled={!canEdit}
                                                className={'inputNumber'}
                                                placeholder={'Min'}
                                              />
                                            </Form.Item>

                                            <Form.Item
                                              dependencies={[
                                                ['metadata', field.name, 'metadata', 'min'],
                                                ['metadata', field.name, 'metadata', 'max']
                                              ]}
                                              labelCol={{ span: 6 }}
                                              labelAlign={'right'}
                                              className={'rowItemExtraData'}
                                              label={'Max'}
                                              validateTrigger={['onChange', 'onBlur']}
                                              rules={[
                                                ({ getFieldValue }) => ({
                                                  validator(_, value) {
                                                    return validateMax(
                                                      getFieldValue([
                                                        'metadata',
                                                        field.name,
                                                        'metadata',
                                                        'min'
                                                      ]),
                                                      value
                                                    );
                                                  }
                                                })
                                              ]}
                                              name={[field.name, 'metadata', 'max']}>
                                              <InputNumber
                                                disabled={!canEdit}
                                                className={'inputNumber'}
                                                placeholder={'Max'}
                                              />
                                            </Form.Item>
                                            <Form.Item
                                              labelCol={{ span: 8 }}
                                              labelAlign={'left'}
                                              className={'rowItemExtraData'}
                                              dependencies={[
                                                ['metadata', field.name, 'metadata', 'min'],
                                                ['metadata', field.name, 'metadata', 'max']
                                              ]}
                                              label={'Default'}
                                              validateTrigger={['onChange', 'onBlur']}
                                              rules={[
                                                ({ getFieldValue }) => ({
                                                  validator(_, value) {
                                                    return validateDefault(
                                                      getFieldValue([
                                                        'metadata',
                                                        field.name,
                                                        'metadata',
                                                        'min'
                                                      ]),
                                                      getFieldValue([
                                                        'metadata',
                                                        field.name,
                                                        'metadata',
                                                        'max'
                                                      ]),
                                                      value
                                                    );
                                                  }
                                                })
                                              ]}
                                              name={[field.name, 'metadata', 'default']}>
                                              <InputNumber
                                                disabled={!canEdit}
                                                className={'inputNumber'}
                                                placeholder={'Default'}
                                              />
                                            </Form.Item>
                                          </div>
                                        );
                                      }

                                      if (
                                        form.getFieldValue([
                                          'metadata',
                                          field.name,
                                          'metadata',
                                          'type'
                                        ]) === WorkItemType.BOOLEAN
                                      ) {
                                        return (
                                          <div className={'metadataRow'}>
                                            <Form.Item
                                              labelCol={{ span: 6 }}
                                              labelAlign={'left'}
                                              className={'rowItemExtraData'}
                                              label={'Default'}
                                              name={[field.name, 'metadata', 'default']}>
                                              <Select
                                                disabled={!canEdit}
                                                className={'inputNumber'}
                                                placeholder={'Choose'}
                                                allowClear={true}
                                                options={BooleanOptions}
                                              />
                                            </Form.Item>
                                          </div>
                                        );
                                      }

                                      if (
                                        form.getFieldValue([
                                          'metadata',
                                          field.name,
                                          'metadata',
                                          'type'
                                        ]) === WorkItemType.TEXT
                                      ) {
                                        return (
                                          <div className={'metadataRow'}>
                                            <Form.Item
                                              labelCol={{ span: 6 }}
                                              labelAlign={'left'}
                                              className={'rowItemExtraData'}
                                              label={'Display'}
                                              name={[field.name, 'metadata', 'displayMode']}
                                              validateTrigger={['onBlur', 'onChange']}
                                              rules={[
                                                ({ getFieldValue }) => ({
                                                  validator(_, value) {
                                                    return validateDisplayMode(
                                                      getFieldValue(['metadata']),
                                                      value
                                                    );
                                                  }
                                                })
                                              ]}>
                                              <Select
                                                disabled={!canEdit}
                                                className={'selectType'}
                                                placeholder={'Display mode'}
                                                options={DisplayModeText}
                                                allowClear={true}
                                              />
                                            </Form.Item>
                                            <Form.Item
                                              labelAlign={'left'}
                                              labelCol={{ span: 10 }}
                                              dependencies={[
                                                ['metadata', field.name, 'metadata', 'minLength'],
                                                ['metadata', field.name, 'metadata', 'maxLength']
                                              ]}
                                              className={'rowItemExtraData'}
                                              label={'Min Length'}
                                              name={[field.name, 'metadata', 'minLength']}
                                              rules={[
                                                ({ getFieldValue }) => ({
                                                  validator(_, value) {
                                                    return validateMin(
                                                      getFieldValue([
                                                        'metadata',
                                                        field.name,
                                                        'metadata',
                                                        'maxLength'
                                                      ]),
                                                      value,
                                                      true
                                                    );
                                                  }
                                                })
                                              ]}>
                                              <InputNumber
                                                disabled={!canEdit}
                                                className={'inputNumber'}
                                                placeholder={'Min Length'}
                                              />
                                            </Form.Item>
                                            <Form.Item
                                              labelCol={{ span: 10 }}
                                              labelAlign={'left'}
                                              className={'rowItemExtraData'}
                                              dependencies={[
                                                ['metadata', field.name, 'metadata', 'minLength'],
                                                ['metadata', field.name, 'metadata', 'maxLength']
                                              ]}
                                              label={'Max Length'}
                                              name={[field.name, 'metadata', 'maxLength']}
                                              rules={[
                                                ({ getFieldValue }) => ({
                                                  validator(_, value) {
                                                    return validateMax(
                                                      getFieldValue([
                                                        'metadata',
                                                        field.name,
                                                        'metadata',
                                                        'minLength'
                                                      ]),
                                                      value
                                                    );
                                                  }
                                                })
                                              ]}>
                                              <InputNumber
                                                disabled={!canEdit}
                                                className={'inputNumber'}
                                                placeholder={'Max Length'}
                                              />
                                            </Form.Item>
                                          </div>
                                        );
                                      }

                                      if (
                                        form.getFieldValue([
                                          'metadata',
                                          field.name,
                                          'metadata',
                                          'type'
                                        ]) === WorkItemType.IMAGE
                                      ) {
                                        return (
                                          <div className={'metadataRow'}>
                                            <Form.Item
                                              labelAlign={'right'}
                                              labelCol={{ span: 6 }}
                                              className={'rowItemExtraData'}
                                              dependencies={[
                                                ['metadata', field.name, 'metadata', 'min'],
                                                ['metadata', field.name, 'metadata', 'max']
                                              ]}
                                              label={'Min'}
                                              name={[field.name, 'metadata', 'min']}
                                              rules={[
                                                ({ getFieldValue }) => ({
                                                  validator(_, value) {
                                                    return validateMin(
                                                      getFieldValue([
                                                        'metadata',
                                                        field.name,
                                                        'metadata',
                                                        'max'
                                                      ]),
                                                      value,
                                                      true
                                                    );
                                                  }
                                                })
                                              ]}>
                                              <InputNumber
                                                disabled={!canEdit}
                                                className={'inputNumber'}
                                                placeholder={'Min'}
                                              />
                                            </Form.Item>
                                            <Form.Item
                                              labelCol={{ span: 6 }}
                                              labelAlign={'right'}
                                              className={'rowItemExtraData'}
                                              label={'Max'}
                                              dependencies={[
                                                ['metadata', field.name, 'metadata', 'min'],
                                                ['metadata', field.name, 'metadata', 'max']
                                              ]}
                                              name={[field.name, 'metadata', 'max']}
                                              rules={[
                                                ({ getFieldValue }) => ({
                                                  validator(_, value) {
                                                    return validateMax(
                                                      getFieldValue([
                                                        'metadata',
                                                        field.name,
                                                        'metadata',
                                                        'min'
                                                      ]),
                                                      value
                                                    );
                                                  }
                                                })
                                              ]}>
                                              <InputNumber
                                                disabled={!canEdit}
                                                className={'inputNumber'}
                                                placeholder={'Max'}
                                              />
                                            </Form.Item>
                                            <Form.Item
                                              labelCol={{ span: 7 }}
                                              labelAlign={'left'}
                                              className={'rowItemSelectExtraData'}
                                              initialValue={false}
                                              valuePropName={'checked'}
                                              name={[
                                                field.name,
                                                'metadata',
                                                'displayingOnListView'
                                              ]}
                                              rules={[
                                                ({ getFieldValue }) => ({
                                                  validator(_, value) {
                                                    return validateDisplayOnListView(
                                                      getFieldValue(['metadata']),
                                                      value
                                                    );
                                                  }
                                                })
                                              ]}>
                                              <Checkbox>Display on List View</Checkbox>
                                            </Form.Item>
                                          </div>
                                        );
                                      }

                                      if (
                                        form.getFieldValue([
                                          'metadata',
                                          field.name,
                                          'metadata',
                                          'type'
                                        ]) === WorkItemType.LOCATION
                                      ) {
                                        return (
                                          <div className={'metadataRow'}>
                                            <Form.Item
                                              labelCol={{ span: 7 }}
                                              labelAlign={'left'}
                                              className={'rowItemSelectExtraData'}
                                              valuePropName={'checked'}
                                              name={[field.name, 'metadata', 'isUsedForDisplaying']}
                                              rules={[
                                                ({ getFieldValue }) => ({
                                                  validator(_, value) {
                                                    return validateDisplaying(
                                                      getFieldValue(['metadata']),
                                                      value
                                                    );
                                                  }
                                                })
                                              ]}>
                                              <Checkbox disabled={!canEdit}>
                                                Use for Displaying
                                              </Checkbox>
                                            </Form.Item>
                                          </div>
                                        );
                                      }

                                      if (
                                        form.getFieldValue([
                                          'metadata',
                                          field.name,
                                          'metadata',
                                          'type'
                                        ]) === WorkItemType.SELECTION
                                      ) {
                                        return (
                                          <>
                                            <div className={'metadataRow'}>
                                              <Form.Item
                                                labelAlign={'right'}
                                                labelCol={{ span: 6 }}
                                                className={'rowItemExtraData'}
                                                label={'Min'}
                                                name={[field.name, 'metadata', 'min']}
                                                dependencies={[
                                                  ['metadata', field.name, 'metadata', 'min'],
                                                  ['metadata', field.name, 'metadata', 'max']
                                                ]}
                                                rules={[
                                                  ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                      return validateMin(
                                                        getFieldValue([
                                                          'metadata',
                                                          field.name,
                                                          'metadata',
                                                          'max'
                                                        ]),
                                                        value,
                                                        true
                                                      );
                                                    }
                                                  })
                                                ]}>
                                                <InputNumber
                                                  disabled={!canEdit}
                                                  className={'inputNumber'}
                                                  placeholder={'Min'}
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                labelCol={{ span: 6 }}
                                                labelAlign={'right'}
                                                className={'rowItemExtraData'}
                                                label={'Max'}
                                                name={[field.name, 'metadata', 'max']}
                                                dependencies={[
                                                  ['metadata', field.name, 'metadata', 'min'],
                                                  ['metadata', field.name, 'metadata', 'max']
                                                ]}
                                                rules={[
                                                  ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                      return validateMax(
                                                        getFieldValue([
                                                          'metadata',
                                                          field.name,
                                                          'metadata',
                                                          'min'
                                                        ]),
                                                        value
                                                      );
                                                    }
                                                  })
                                                ]}>
                                                <InputNumber
                                                  disabled={!canEdit}
                                                  className={'inputNumber'}
                                                  placeholder={'Max'}
                                                />
                                              </Form.Item>
                                            </div>
                                            <Collapse
                                              style={{ marginTop: 12 }}
                                              defaultActiveKey={[]}>
                                              <Collapse.Panel header="Select items" key="1">
                                                <div className={'rowItemSelection'}>
                                                  <Form.List
                                                    name={[field.name, 'metadata', 'items']}>
                                                    {(
                                                      fieldItems,
                                                      { add: addItem, remove: removeItem }
                                                    ) => (
                                                      <>
                                                        {fieldItems.map((fieldSelection, index) => {
                                                          return (
                                                            <div
                                                              key={field.key}
                                                              className={'metaDataRowItems'}>
                                                              <Form.Item
                                                                labelAlign={'right'}
                                                                labelCol={{ span: 6 }}
                                                                className={'rowItemExtraData'}
                                                                label={'Value'}
                                                                name={[
                                                                  fieldSelection.name,
                                                                  'value'
                                                                ]}
                                                                rules={[
                                                                  {
                                                                    required: true,
                                                                    message:
                                                                      'This is required field'
                                                                  },
                                                                  ({ getFieldValue }) => ({
                                                                    validator(_, value) {
                                                                      return validateSelection(
                                                                        getFieldValue([
                                                                          'metadata',
                                                                          field.name,
                                                                          'metadata',
                                                                          'items'
                                                                        ]),
                                                                        'value',
                                                                        value
                                                                      );
                                                                    }
                                                                  })
                                                                ]}>
                                                                <Input
                                                                  disabled={!canEdit}
                                                                  className={'inputNumber'}
                                                                  placeholder={'Input Value'}
                                                                />
                                                              </Form.Item>
                                                              <Form.Item
                                                                labelAlign={'right'}
                                                                labelCol={{ span: 6 }}
                                                                className={'rowItemExtraData'}
                                                                label={'Name'}
                                                                name={[fieldSelection.name, 'name']}
                                                                rules={[
                                                                  {
                                                                    required: true,
                                                                    message:
                                                                      'This is required field'
                                                                  },
                                                                  ({ getFieldValue }) => ({
                                                                    validator(_, value) {
                                                                      return validateSelection(
                                                                        getFieldValue([
                                                                          'metadata',
                                                                          field.name,
                                                                          'metadata',
                                                                          'items'
                                                                        ]),
                                                                        'name',
                                                                        value
                                                                      );
                                                                    }
                                                                  })
                                                                ]}>
                                                                <Input
                                                                  disabled={!canEdit}
                                                                  className={'inputNumber'}
                                                                  placeholder={'Input Name'}
                                                                />
                                                              </Form.Item>
                                                              <Button
                                                                disabled={!canEdit}
                                                                className={'btn'}
                                                                danger
                                                                icon={<DeleteOutlined />}
                                                                onClick={() =>
                                                                  removeItem(fieldSelection.name)
                                                                }
                                                              />
                                                            </div>
                                                          );
                                                        })}
                                                        <Form.Item>
                                                          <Button
                                                            className={'btnRemoveItem'}
                                                            disabled={!canEdit}
                                                            type="dashed"
                                                            onClick={() => addItem()}
                                                            style={{ width: '100%' }}
                                                            icon={<PlusOutlined />}>
                                                            Add Selection Items
                                                          </Button>
                                                        </Form.Item>
                                                      </>
                                                    )}
                                                  </Form.List>
                                                </div>
                                              </Collapse.Panel>
                                            </Collapse>
                                          </>
                                        );
                                      }
                                    }}
                                  </Form.Item>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      <Form.Item
                        className="addMetadataBtn"
                        labelCol={{ span: 0 }}
                        wrapperCol={{ span: 24 }}>
                        <Button
                          type="dashed"
                          disabled={!canEdit}
                          danger
                          onClick={() => add()}
                          style={{ width: '100%' }}
                          icon={<PlusOutlined />}>
                          Add Metadata
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Form>
    </Modal>
  );
};

export default CreateProjectModal;
