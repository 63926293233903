import React from 'react';
import { Button, Card, Descriptions, Input, List, message, PageHeader, Skeleton } from 'antd';
import Request from '../../api/request';

import './style.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAppContext } from '../../context';
import { useNavigate } from 'react-router-dom';
import CreateCompanyModal from './components/CreateCompanyModal';
import { ICompany } from './interface';
const { Search } = Input;

const Company = () => {
  const { appState, setAppState } = useAppContext();

  // //if no login redirect to login
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!appState?.user) {
      navigate('/');
    }
  }, []);

  const loadingCompany = React.useRef(false);
  const [keyWork, setKeyWork] = React.useState('');
  const [companies, setCompanies] = React.useState<ICompany[]>([]);
  const [hasMore, setHasMore] = React.useState(true);
  const cursorLoadMore = React.useRef({});
  const onLoadCompany = async () => {
    try {
      if (loadingCompany.current) {
        return;
      }
      loadingCompany.current = true;
      const response = await Request.getAuth('/companies', {
        limit: 20,
        searchKeyword: keyWork,
        ...cursorLoadMore.current
      });

      const { companies: newCompanies, cursor } = response.data.data;
      cursorLoadMore.current = cursor;
      if (newCompanies.length < 20) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setCompanies((prevState) => [...prevState, ...newCompanies]);
    } catch (error) {
      message.error('Fail to get company');
    } finally {
      loadingCompany.current = false;
    }
  };

  const reloadData = () => {
    setCompanies([]);
    setHasMore(true);
    cursorLoadMore.current = {};
    onLoadCompany();
  };
  React.useEffect(() => {
    reloadData();
  }, [keyWork]);

  // add user modal

  const [visibleModalAddCompany, setVisibleModalAddCompany] = React.useState(false);
  return (
    <div className={'users'}>
      <PageHeader
        className={'header'}
        title={'Companies'}
        subTitle={
          <Search
            style={{ width: 500 }}
            placeholder="Search key work..."
            allowClear
            onSearch={(searchKey) => {
              setKeyWork(searchKey);
            }}
          />
        }
        extra={[
          <Button
            key="add_user_btn"
            type={'primary'}
            onClick={() => setVisibleModalAddCompany(true)}>
            Add Company
          </Button>
        ]}
      />
      <div id="scrollableDiv" className={'userGridView'}>
        <InfiniteScroll
          dataLength={companies.length}
          next={onLoadCompany}
          hasMore={hasMore}
          loader={
            <Card style={{ width: 300, marginTop: 16 }} loading={loadingCompany.current}>
              <Skeleton loading={loadingCompany.current} active />
            </Card>
          }
          scrollableTarget="scrollableDiv">
          <List
            grid={{
              gutter: 8,
              xs: 1,
              sm: 1,
              md: 2,
              lg: 3,
              xl: 4,
              xxl: 4
            }}
            loading={loadingCompany.current}
            dataSource={companies}
            renderItem={(company) => (
              <Card hoverable title={company.name} style={{ margin: 8 }}>
                <Descriptions column={2}>
                  <Descriptions.Item label={'Projects'}>
                    {company.numberOfProjects}
                  </Descriptions.Item>
                  <Descriptions.Item label={'Employees'}>
                    {company.numberOfEmployees}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            )}
          />
        </InfiniteScroll>
      </div>
      <CreateCompanyModal
        visible={visibleModalAddCompany}
        onClose={() => {
          setVisibleModalAddCompany(false);
          reloadData();
        }}
      />
    </div>
  );
};

export default Company;
