import { Layout, Menu } from 'antd';
import React from 'react';
import { useAppContext } from '../../context';
import { DashboardOutlined, ProfileOutlined, UserOutlined } from '@ant-design/icons';
import { SIDEBAR_CONFIG } from './sidebar_config';
import { Link, useLocation } from 'react-router-dom';
import { EUserRole } from '../../pages/User/interface';

const { Sider } = Layout;

const AppSideBar = () => {
  const { appState = {} } = useAppContext();
  const { user, collapsedSideBar } = appState;
  const { company, role } = user || {};
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = React.useState([]);
  useLocation;
  React.useLayoutEffect(() => {
    if (location.pathname.includes('/projects')) {
      setSelectedKeys([SIDEBAR_CONFIG.PROJECTS]);
    } else if (location.pathname.includes('/user')) {
      setSelectedKeys([SIDEBAR_CONFIG.USER]);
    } else if (location.pathname.includes('/dashboard')) {
      setSelectedKeys([SIDEBAR_CONFIG.DASHBOARD]);
    } else if (location.pathname.includes('/companies')) {
      setSelectedKeys([SIDEBAR_CONFIG.COMPANY]);
    }
  }, [location]);
  if (!user) return null;
  return (
    <Sider
      width={200}
      style={{ height: '100vh', background: 'white' }}
      collapsed={collapsedSideBar}>
      <Menu
        theme="light"
        mode="inline"
        className={'menu'}
        selectedKeys={selectedKeys}
        items={[
          {
            key: SIDEBAR_CONFIG.DASHBOARD,
            icon: <DashboardOutlined />,
            label: <Link to={'/dashboard'}>{SIDEBAR_CONFIG.DASHBOARD}</Link>
          },
          {
            key: SIDEBAR_CONFIG.PROJECTS,
            icon: <ProfileOutlined />,
            label: <Link to={'/projects'}>{SIDEBAR_CONFIG.PROJECTS}</Link>
          },
          ...(!company
            ? [
                {
                  key: SIDEBAR_CONFIG.COMPANY,
                  icon: <ProfileOutlined />,
                  label: <Link to={'/companies'}>{SIDEBAR_CONFIG.COMPANY}</Link>
                }
              ]
            : []),
          ...(role === EUserRole.ADMIN
            ? [
                {
                  key: SIDEBAR_CONFIG.USER,
                  icon: <UserOutlined />,
                  label: <Link to={'/users'}>{SIDEBAR_CONFIG.USER}</Link>
                }
              ]
            : [])
        ]}
      />
    </Sider>
  );
};
export default AppSideBar;
