import React from 'react';
import { Avatar, Card, List, Skeleton, Typography } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import Request from '../../../api/request';
import { INotification, NotificationType } from '../../../pages/Projects/interface';
import moment from 'moment';
import CommentDescription from '../../../pages/ProjectDetail/components/CommentDescription';
import ProjectWorkItemCommentWrapper from './ProjectWorkItemCommentWrapper';
import ProjectDetailWrapper from './ProjectDetailWrapper';

const Notification = () => {
  const [notifications, setNotifications] = React.useState<INotification[]>([]);
  const [hasMore, setHasMore] = React.useState(true);
  const loadingNotification = React.useRef(false);
  const cursorLoadMore = React.useRef({});

  const onLoadMoreNotification = async () => {
    if (loadingNotification.current) {
      return;
    }
    loadingNotification.current = true;
    const response = await Request.getAuth('/notifications', {
      limit: 20,
      ...cursorLoadMore.current
    });

    const { notifications: newNotifications, cursor } = response.data.data;
    cursorLoadMore.current = cursor;
    if (newNotifications.length < 20) {
      setHasMore(false);
    }
    setNotifications((prevState) => [...prevState, ...newNotifications]);
    loadingNotification.current = false;
  };

  const [openWorkItemComment, setOpenWorkItemComment] = React.useState(false);
  const [openProjectDetail, setOpenProjectDetail] = React.useState(false);
  const [notificationFocused, setNotificationFocused] = React.useState<INotification>();
  const showItemDetail = async (item: INotification) => {
    setNotificationFocused(item);
    if (
      item.type === NotificationType.projectPermissionGranted ||
      item.type === NotificationType.projectPermissionRevoked
    ) {
      setOpenProjectDetail(true);
    }
    if (item.type === NotificationType.comment) {
      setOpenWorkItemComment(true);
    }
    return;
  };

  React.useEffect(() => {
    if (open) {
      onLoadMoreNotification();
    }
  }, []);
  return (
    <div id={'scrollableNotificationDiv'} style={{ width: 500, maxHeight: 600, overflow: 'auto' }}>
      <InfiniteScroll
        dataLength={notifications.length}
        next={onLoadMoreNotification}
        hasMore={hasMore}
        loader={
          <Card>
            <Skeleton loading={loadingNotification.current} avatar active />
          </Card>
        }
        scrollableTarget="scrollableNotificationDiv">
        <List
          dataSource={notifications}
          renderItem={(item) => (
            <List.Item style={{ alignItems: 'flex-start' }} onClick={() => showItemDetail(item)}>
              <Avatar
                size={'small'}
                style={{ width: 40, height: 40, marginRight: 8 }}
                shape={'circle'}
                src={item.sender.avatar}
              />
              <div style={{ flex: 1 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ fontWeight: 500, fontSize: 14 }}>{item.sender.email}</div>
                  <div style={{ fontSize: 12, color: 'rgba(0,0,0,0.7)' }}>
                    {moment(item.createdAt).format('MMM DD, YYYY HH:mm')}
                  </div>
                </div>
                <div>
                  {(item.type === NotificationType.projectPermissionGranted ||
                    item.type === NotificationType.projectPermissionRevoked) && (
                    <Typography.Paragraph ellipsis={{ rows: 2, tooltip: item.message }}>
                      {item.message}
                    </Typography.Paragraph>
                  )}
                  {item.type === NotificationType.comment && (
                    <CommentDescription data={item.comment} />
                  )}
                </div>
              </div>
            </List.Item>
          )}
        />
      </InfiniteScroll>
      {notificationFocused && notificationFocused.type === NotificationType.comment && (
        <ProjectWorkItemCommentWrapper
          visible={openWorkItemComment}
          onClose={() => setOpenWorkItemComment(false)}
          projectID={notificationFocused.project.projectID}
          workItemID={notificationFocused.comment.workItemID}
        />
      )}
      {notificationFocused &&
        (notificationFocused.type === NotificationType.projectPermissionGranted ||
          notificationFocused.type === NotificationType.projectPermissionRevoked) && (
          <ProjectDetailWrapper
            type={notificationFocused.type}
            visible={openProjectDetail}
            title={
              notificationFocused.type === NotificationType.projectPermissionGranted
                ? `You have been granted access to ${notificationFocused.project?.name}`
                : ` You have been revoked access to ${notificationFocused.project?.name}`
            }
            onClose={() => setOpenProjectDetail(false)}
            projectID={notificationFocused.project.projectID}
          />
        )}
    </div>
  );
};

export default Notification;
