export enum EUserRole {
  ADMIN = 1,
  VERIFY = 3,
  EDITOR = 2
}

export interface IUser {
  id: string;
  email: string;
  name: string;
  avatar: string;
  role: EUserRole;
}
